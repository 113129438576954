<template>
  <main class="fond-mix">
    <router-link to="/form"
      ><div
        class="absolute w-16 h-16 bg-transparent left-0 xl:left-auto xl:right-0 z-50 cursor-pointer"
      ></div
    ></router-link>
    <StatusView v-if="id && uid" :id="id" :uid="uid" />
    <div class="w-full relative">
      <div
        class="h-full fixed top-0 right-0 bg-black w-[20vw] hidden tablet:block"
      ></div>
      <img
        v-if="!end"
        src="@/assets/images/star2.png"
        class="absolute bottom-0 w-[8.5vw] left-[41vw] max-w-[250px] hidden tablet:block"
      />

      <img
        src="@/assets/images/star1.png"
        class="absolute w-[7vw] top-[6vw] right-0 max-w-[160px]"
      />

      <div class="w-full" />
      <div
        class="w-full m-auto h-full tablet:grid tablet:grid-cols-2 text-white -mt-[1px] hidden max-w-[2000px]"
      >
        <div class="relative">
          <img
            v-if="!end"
            src="@/assets/images/left.png"
            class="max-w-[505px] desktop:max-w-[505px] middle:max-w-[710px] mt-[2%] pl-[6%] w-full m-auto"
          />
          <img
            v-if="end"
            src="@/assets/images/end/left.png"
            class="max-w-[505px] desktop:max-w-[505px] middle:max-w-[710px] mt-[12%] pl-[6%] w-full m-auto"
          />
          <div v-if="!end" class="flex justify-center flex-wrap mt-8">
            <a @click="tagMePart()" class="button angi-bold cursor-pointer"
              >JE PARTICIPE !</a
            >

            <p v-if="!end" class="w-full text-center text-lg my-8">
              *règlement du jeu sur
              <a
                v-if="!end"
                class="underline"
                href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/reglement.pdf"
                target="_blank"
                >www.cafegrandmere.fr</a
              >
            </p>
          </div>
        </div>
        <div class="relative">
          <img
            v-if="!end"
            src="@/assets/images/right.png"
            class="max-w-[505px] desktop:desktop:max-w-[535px] middle:max-w-[745px] mt-[2%] pl-[6%] w-full m-auto"
          />

          <img
            v-if="end"
            src="@/assets/images/end/right.png"
            class="max-w-[505px] desktop:desktop:max-w-[535px] middle:max-w-[745px] mt-[2%] pl-[6%] w-full m-auto"
          />

          <div class="flex justify-center w-full mt-8 pl-[6%]">
            <a @click="tagMePerso()" class="button angi-bold cursor-pointer"
              >JE PERSONNALISE !</a
            >
          </div>
        </div>
      </div>
      <div class="tablet:hidden relative">
        <div class="relative">
          <a
            href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/reglement.pdf"
            target="_blank"
          >
            <div
              v-if="!end"
              class="w-full absolute bg-transparent cursor-pointer h-48 block top-[175vw] left-0 z-20"
            ></div
          ></a>

          <img
            v-if="!end"
            src="@/assets/images/starmob1.png"
            class="absolute right-0 top-[15%] w-[18%] max-w-[120px]"
          />
          <img
            v-if="!end"
            src="@/assets/images/home-mobile.png"
            class="m-auto w-full max-w-[555px]"
          />
          <img
            v-if="end"
            src="@/assets/images/end/mobileend.png"
            class="m-auto w-full max-w-[555px] mt-16"
          />

          <p
            v-if="!end"
            class="absolute w-full top-[150vw] text-center text-sm text-white"
          >
            <router-link
              to="/form"
              class="bg-transparent w-full block h-[20vw] cursor-pointer"
            ></router-link
            ><br />
          </p>
        </div>

        <div v-if="!end" class="bg-black pb-10 flex justify-center w-full">
          <a
            @click="tagMePerso()"
            class="button angi-bold !text-xl cursor-pointer"
            >JE PERSONNALISE !</a
          >
        </div>
        <div
          v-if="end"
          class="flex justify-center w-full -mt-[90px] relative z-20"
        >
          <a
            @click="tagMePerso()"
            class="button angi-bold !text-xl cursor-pointer"
            >JE PERSONNALISE !</a
          >
        </div>
      </div>
    </div>
    <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
  </main>
</template>
<script>
import StatusView from "@/components/StatusView.vue";

export default {
  name: "App",
  data() {
    return {
      id: undefined,
      uid: undefined,
      lot: "perdu",
      end: this.$root.configOp.datas.end,
    };
  },
  components: {
    StatusView,
  },
  inject: ["store"],
  mounted() {
    console.log(this.end);
    if (this.$route.params._sub_id) {
      this.id = this.$route.params._sub_id;
    }
    if (this.$route.params._key) {
      this.uid = this.$route.params._key;
    }
  },
  methods: {
    tagMePart() {
      // eslint-disable-next-line no-undef
      if (umami) umami.track("je participe");
      this.$gtag.event("click", {
        event_category: "bouton",
        event_label: "je participe",
        value: "ok",
      });
      this.$router.push("/form");
    },
    tagMePerso() {
      // eslint-disable-next-line no-undef
      if (umami) umami.track("je personnalise");

      this.$gtag.event("click", {
        event_category: "bouton",
        event_label: "je personnalise",
        value: "ok",
      });
      this.$router.push("/avatar");
    },
  },
};
</script>

<style>
.cls-1 {
  fill: none;
}

.cls-2 {
  fill: #fac79f;
}

.cls-3 {
  fill: #1d1d1b;
}

.bg-mememob {
  height: 800px;
  background-image: url("@/assets/images/home-mobile-bottom.png");
  background-repeat: no-repeat;
  background-position: calc(50% - 20px) 75px;
}

@media screen and (max-width: 600px) {
  .bg-mememob {
    background-position: calc(50% - 20px) 45px;
  }
}

.scb::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.scb::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
.scb::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 25px;
}

/* Handle on hover */
.scb::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
