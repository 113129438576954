<template>
  <main class="relative">
    <router-link to="/" class="block absolute h-[30%] w-[85%] bg-transparent z-10 inset-0 cursor-pointer tablet:hidden" />
    <span class="relative block max-w-[1920px] m-auto">
      <div
        @click.prevent="goToStatus"
        v-if="win"
        class="h-[5%] bg-transparent w-[4%] absolute top-[37%] left-[55.5%] cursor-pointer hidden tablet:block"
      ></div>
      <img
        v-if="win"
        src="@/assets/images/dotaboite-min.png"
        class="tablet:block hidden"
        alt=""
      />
      <img
        v-else
        src="@/assets/images/dotalose-min.png"
        class="tablet:block hidden"
        alt=""
      />

      <img
        v-if="win"
        src="@/assets/images/dotalosemobile-min.png"
        class="tablet:hidden block m-auto w-full max-w-[555px]"
        alt=""
      />
      <img
        v-else
        src="@/assets/images/dotalosemobile-min.png"
        class="tablet:hidden block m-auto w-full max-w-[555px]"
        alt=""
      />

      <p
        v-if="win"
        class="absolute w-full top-[92%] tablet:top-[53.5%] text-center text-sm pb-6 text-white"
      >
        <router-link to="/avatar" class="button !text-2xl angi-bold uppercase"
          >Je crée mon image</router-link
        ><br />
      </p>
      <p v-else class="absolute w-full bottom-[8%] text-center text-white">
        <router-link to="/avatar" class="button angi-bold !text-xl uppercase"
          >Je personnalise !</router-link
        ><br />
      </p>
    </span>
    <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
  </main>
</template>

<script>
export default {
  name: "ResultView",
  data() {
    return {
      lot: undefined,
      win: false,
    };
  },

  mounted() {
    if (this.$route.query.lot === "boite") {
      this.lot = "boite";
      this.win = true;
    } else {
      this.lot = sessionStorage.getItem("lot") || "perdu";
    }
    if (this.lot === "boite") {
      this.win = true;
    }
  },
  methods: {
    error() {
      let obj = {
        title: "Erreur",
        message: "Aucune information sur le status de votre participation",
        type: "error",
        customCloseBtnText: "Fermer",
      };
      this.$refs.simplert.openSimplert(obj);
    },
    goToStatus() {
      let id = sessionStorage.getItem("id");
      let uid = sessionStorage.getItem("uid");
      if (id && uid) {
        this.$router.push(`/avatar?id=${id}&uid=${uid}&open=true`);
      } else {
        this.error();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  @apply text-4xl;

  @media screen and (max-width: 1024px) {
    @apply text-2xl;
  }

  @media screen and (max-width: 460px) {
    @apply text-xl;
  }

  @media screen and (max-width: 375px) {
    font-size: 15px !important;
  }
}
</style>
