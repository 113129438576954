<template>
  <main class="flex justify-center">
    <router-link to="/" class="block absolute h-[30%] w-[85%] bg-transparent z-10 inset-0 cursor-pointer tablet:hidden" />
    <div class="w-auto max-w-[1920px] tablet:h-[50vw] max-h-[930px]">
      <span class="block !relative">
        <img
          src="@/assets/images/dotations-mobile-min.png"
          class="m-auto -mt-3 w-full max-w-[1920px] tablet:hidden block"
        />

        <img
          src="@/assets/images/dotations-min.png"
          class="m-auto mt-6 w-full max-w-[1300px] tablet:block hidden"
        />

        <div>
          <div
            @click.prevent="goTo('https://www.cafegrandmere.fr/moulu/')"
            class="h-[8%] w-[35%] bottom-[35%] left-[32%] tablet:h-[14%] tablet:w-[20%] absolute bg-transparent tablet:left-[20%] tablet:bottom-0 cursor-pointer"
          ></div>
          <div
            @click.prevent="goTo('https://www.cafegrandmere.fr/dosette/')"
            class="h-[8%] w-[35%] bottom-0 left-[10%] tablet:h-[14%] tablet:w-[18%] absolute bg-transparent tablet:left-[55%] tablet:bottom-0 cursor-pointer"
          ></div>
          <div
            @click.prevent="goTo('https://www.cafegrandmere.fr/tassimo/')"
            class="h-[8%] w-[37%] bottom-0 left-[52%] tablet:h-[14%] tablet:w-[16%] absolute bg-transparent tablet:left-[78%] tablet:bottom-0 cursor-pointer"
          ></div>
        </div>
      </span>
    </div>
  </main>
</template>

<script>
export default {
  name: "App",
  methods: {
    goTo(url) {
      let lien = document.createElement("a");
      lien.href = url;
      lien.target = "_blank";
      lien.click();
    },
  },
};
</script>
